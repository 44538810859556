import { useState } from 'react'
import { useRequestContext } from '../providers/request-provider'
import HeaderActions from './header-actions'
import RequestName from './request-name'
import { TicketStatusBadge } from 'lib/components/badge/badge'
import HeaderDropdowns from './header-dropdowns'
import Breadcrumb from 'lib/components/breadcrumb/breadcrumb'
import HeaderRequestDetails from 'components/pages/request/request-header/header-request-details'
import CloseButton from '../media/close-button'
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react'

import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import RequestsProjectsBreadcrumb from 'lib/components/breadcrumb/requests-projects-breadcrumb'

export default function RequestHeader(): JSX.Element {
  const [isOpen, setIsOpen] = useState(true)
  const { ticket, showRevisions, fetchAndSetTicket, invalidFields, showValidationErrors } = useRequestContext()
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()

  function toggleOpen() {
    setIsOpen(!isOpen)
  }

  return (
    <header className="tw-relative">
      <>
        <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2 tw-px-3 tw-py-1">
          {isFeatureFlagEnabled('projects') ? (
            <RequestsProjectsBreadcrumb
              className="tw-mt-3"
              ticket={ticket}
              onProjectUpdate={() => fetchAndSetTicket()}
            />
          ) : (
            <Breadcrumb className="tw-ml-6 tw-mt-3" text="Back to all requests" href="/requests" />
          )}
          <div className="tw-flex tw-items-center tw-gap-2">
            <HeaderActions />
          </div>
        </div>
        {isOpen && (
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-px-3">
            <RequestName
              showValidationError={invalidFields?.includes('subject') && showValidationErrors}
              wrapperClassName="tw-min-w-12 tw-max-w-full tw-w-fit tw-flex-grow"
              className="tw-w-full"
            />

            <TicketStatusBadge status={ticket?.status} className="tw-mr-2" />
            {!showRevisions && <HeaderDropdowns />}
            {showRevisions && <HeaderRequestDetails />}
          </div>
        )}
        <CloseButton Icon={isOpen ? ChevronUpIcon : ChevronDownIcon} onClick={toggleOpen} position="bottom" />
      </>
    </header>
  )
}
