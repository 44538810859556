import { useRequestContext } from '../providers/request-provider'
import { HTMLAttributes, useEffect, useState } from 'react'
import { cn } from 'lib/util/cn'
import TextInput from 'components/core/text-input/text-input'

export default function RequestName({
  showValidationError,
  className,
  wrapperClassName,
}: {
  showValidationError: boolean
  className?: HTMLAttributes<HTMLDivElement>['className']
  wrapperClassName?: HTMLAttributes<HTMLDivElement>['className']
}): JSX.Element {
  const { dispatch, ticket, showRevisions } = useRequestContext()
  const [requestSubject, setRequestSubject] = useState(ticket?.subject || '')

  function handleBlur() {
    if (ticket.subject !== requestSubject) {
      dispatch('subject', { subject: requestSubject })
    }
  }

  function handleChange(e) {
    setRequestSubject(e.target.value)
  }

  useEffect(() => {
    if (ticket?.subject) {
      setRequestSubject(ticket.subject)
    }
  }, [ticket?.subject])

  return (
    <>
      {showRevisions ? (
        <h3 className={cn('tw-m-0 tw-ml-3', className)}>{requestSubject}</h3>
      ) : (
        <TextInput
          name="requestName"
          onBlur={handleBlur}
          onChange={handleChange}
          value={requestSubject}
          label="Request Name"
          wrapperClassName={wrapperClassName}
          hideLabel
          placeholder="Enter a request name"
          className={className}
          hasError={showValidationError}
          focusInput
        />
      )}
    </>
  )
}
