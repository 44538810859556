import { ColumnDef, flexRender, getCoreRowModel, RowSelectionState, Table, useReactTable } from '@tanstack/react-table'
import Button, { LinkLikeButton } from 'components/core/button'
import { Ticket } from 'interfaces/ticket'
import { TicketStatusBadge } from 'lib/components/badge/badge'
import Checkbox from 'lib/components/checkbox/checkbox'
import { FolderXIcon, PlusIcon, TicketIcon } from 'lucide-react'
import { useEffect, useState, useMemo } from 'react'
import { SubscriptionTypeIcon } from '../deliverable-type'
import { SubscriptionType } from 'interfaces/project-templates'
import { useInfiniteQuery } from '@tanstack/react-query'
import { request } from 'lib/api/fetch-api'
import { Skeleton } from 'lib/components/skeleton/skeleton'
import { useInView } from 'react-intersection-observer'
import { toast } from 'lib/components/toast/toast'
import DeliverablesTableTitleCell from './deliverables-table-title-cell'
import { cn } from 'lib/util/cn'
import ProjectDeliverablesFlyoutMenu from './project-deliverables-flyout-menu'

interface ProjectDeliverablesTableProps {
  projectId: number
  handleAddDeliverablesClick?: () => void
  handleRemoveDeliverablesClick?: (deliverablesToRemove: Ticket[]) => void
  readOnly?: boolean
  refetchProject?: () => void
}

const ProjectDeliverablesTable = ({
  projectId,
  handleAddDeliverablesClick,
  handleRemoveDeliverablesClick,
  readOnly,
}: ProjectDeliverablesTableProps) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const { ref, inView } = useInView()

  const { data, isLoading, error, hasNextPage, fetchNextPage, isFetchingNextPage, refetch, isRefetching } =
    useInfiniteQuery({
      initialPageParam: 1,
      queryKey: ['projectDeliverables', projectId],
      queryFn: ({ pageParam = 1 }) =>
        request({ endpoint: 'getProjectDeliverables', query: { id: projectId, page: pageParam } }),
      getNextPageParam: (lastPage, pages) => (lastPage.meta?.nextPage ? pages.length + 1 : undefined),
      retry: 3,
    })

  const columns: ColumnDef<Ticket>[] = [
    {
      id: 'select-col',
      cell: ({ row, table }) => (
        <Checkbox
          className="tw-m-4"
          isChecked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          onClick={(e) => {
            row.getToggleSelectedHandler()(e)
            table.setGlobalFilter(row.original.subject)
          }}
        />
      ),
      size: 8,
    },
    {
      id: 'title',
      header: 'Title',
      cell: (props) => <DeliverablesTableTitleCell ticket={props.row.original} isAdmin={readOnly} />,
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'friendlyStatusName',
      cell: (props) => <TicketStatusBadge status={props.cell.getValue() as string} />,
    },
    {
      id: 'priority',
      header: 'Queue Priority',
      accessorKey: 'position',
      cell: (props) => (
        <span className="tw-mx-1">{props.cell.getValue() ? (props.cell.getValue() as number) : '-'}</span>
      ),
    },
    {
      id: 'type',
      header: 'Request Type',
      accessorKey: 'category',
      cell: (props) => <SubscriptionTypeIcon type={props.cell.getValue() as SubscriptionType} />,
    },
    {
      id: 'actions',
      enableSorting: false,
      cell: (props) => <ProjectDeliverablesFlyoutMenu onChange={refetch} ticket={props.row.original} />,
      size: 8,
    },
  ]

  const tableData = useMemo(() => data?.pages.flatMap((page) => page.data), [data?.pages]) ?? []

  const table = useReactTable({
    columns,
    data: tableData,
    state: {
      rowSelection,
      columnVisibility: {
        'select-col': !readOnly,
        actions: !readOnly,
      },
    },
    enableRowSelection: !readOnly,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
  })

  useEffect(() => {
    if (error) {
      toast.error('Failed to fetch project requests')
      console.error('Failed to fetch project requests', error)
    }
  }, [error])

  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView, isFetchingNextPage])

  return (
    <div>
      <div className="tw-mb-4 tw-flex tw-items-center tw-justify-between">
        <h5 className="tw-flex tw-items-center tw-gap-1">
          <TicketIcon className="lu-md" />
          <span>Requests ({data?.pages[0].meta.total ?? 0})</span>
        </h5>
        {!readOnly && (
          <LinkLikeButton onClick={handleAddDeliverablesClick} className="tw-flex tw-items-center tw-justify-center">
            <PlusIcon className="lu-md" /> Add request(s)
          </LinkLikeButton>
        )}
      </div>
      <div>
        <table className="tw-w-full tw-border-collapse tw-text-neutral-500">
          <thead className="tw-sticky tw--top-4 tw-z-10">
            <tr className="tw-h-12 tw-w-full tw-bg-peppercorn-50">
              <TableHeaders
                table={table}
                rowSelection={rowSelection}
                tickets={data?.pages.flatMap((page) => page.data)}
                handleRemoveDeliverablesClick={handleRemoveDeliverablesClick}
                readOnly={readOnly}
              />
            </tr>
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="tw-h-24 tw-border-s-2 tw-border-solid tw-border-peppercorn-100 tw-p-2">
                {row.getVisibleCells().map((cell, index) => (
                  <td
                    key={cell.id}
                    style={{ width: `${cell.column.getSize()}px` }}
                    className={cn({ 'tw-bg-cornflower-50': row.getIsSelected(), 'tw-pl-4': readOnly && index === 0 })}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
            {hasNextPage && <tr ref={ref} className="tw-h-4" />}
          </tbody>
        </table>
        {(isLoading || isFetchingNextPage || isRefetching) && (
          <Skeleton className="tw-h-24 tw-w-full tw-bg-neutral-200" data-testid="skeleton" />
        )}
      </div>
    </div>
  )
}
interface BulkActionHeadersProps {
  table: Table<Ticket>
  rowSelection: Record<string, boolean>
  tickets?: Ticket[]
  handleRemoveDeliverablesClick: (deliverablesToRemove: Ticket[]) => void
}

const BulkActionHeaders = ({ table, rowSelection, tickets, handleRemoveDeliverablesClick }: BulkActionHeadersProps) => {
  return (
    <th scope="col" colSpan={12} className="tw-text-left tw-text-xs tw-tracking-wider">
      <div className="tw-ml-4 tw-flex tw-items-center tw-gap-4">
        <Checkbox
          isChecked={Object.keys(rowSelection).length > 0}
          onClick={() => table.toggleAllRowsSelected()}
          indeterminate={Object.keys(rowSelection).length < table.getRowCount()}
        />
        <Button
          onClick={() => {
            handleRemoveDeliverablesClick(Object.keys(rowSelection).map((key) => tickets?.[key]))
          }}
          size="cut"
          color="outlineCherry"
          disabled={Object.keys(rowSelection).length === 0}
          className="tw-flex tw-items-center tw-gap-2 tw-p-2"
        >
          <FolderXIcon className="tw-h-4 tw-w-4" />
          <span>Remove requests</span>
        </Button>
      </div>
    </th>
  )
}

interface TableHeadersProps {
  table: Table<Ticket>
  rowSelection: Record<string, boolean>
  tickets?: Ticket[]
  handleRemoveDeliverablesClick: (deliverablesToRemove: Ticket[]) => void
  readOnly?: boolean
}

const TableHeaders = ({ table, rowSelection, tickets, handleRemoveDeliverablesClick, readOnly }: TableHeadersProps) => {
  return (
    <>
      {Object.keys(rowSelection).length > 0 ? (
        <BulkActionHeaders
          table={table}
          rowSelection={rowSelection}
          tickets={tickets}
          handleRemoveDeliverablesClick={handleRemoveDeliverablesClick}
        />
      ) : (
        table.getFlatHeaders().map((header) => (
          <th
            key={header.id}
            style={{ width: `${header.getSize()}px` }}
            className={cn('tw-black tw-font-medium tw-uppercase', { 'tw-pl-4': readOnly && header.id === 'title' })}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
          </th>
        ))
      )}
    </>
  )
}

export default ProjectDeliverablesTable
