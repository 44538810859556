import { useQuery } from '@tanstack/react-query'
import Button, { LinkLikeButton } from 'components/core/button'
import { LoadingScreen } from 'components/pages/requests/empty-screens'
import { Project } from 'interfaces/project'
import { requestQuery } from 'lib/api/fetch-api'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import DeleteProjectFlyoutButton from './delete-project-flyout-button'
import { FolderOpenIcon } from 'lucide-react'
import projectsImage from 'images/projects/projects.png'
import { useState } from 'react'

export interface ProjectListProps {
  handleProjectClick: (project: Project) => void
}

export default function ProjectList({ handleProjectClick }: ProjectListProps) {
  const [showAll, setShowAll] = useState(false)
  const { data, isLoading } = useQuery({
    queryKey: ['projects'],
    queryFn: requestQuery({
      endpoint: 'getProjects',
    }),
  })

  const projects = data?.data

  const displayedProjects = showAll ? projects : projects?.slice(0, 4)

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <>
      <div className="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-pt-4">
        <h4 className="tw-mb-2 tw-flex tw-items-center tw-gap-1">
          <FolderOpenIcon />
          Projects
        </h4>

        {projects?.length > 4 && (
          <LinkLikeButton color="primary" onClick={() => setShowAll((prev) => !prev)}>
            See {showAll ? 'less' : 'all'}
          </LinkLikeButton>
        )}
      </div>
      <div className="tw-flex tw-flex-wrap tw-gap-2 tw-pb-2">
        {displayedProjects.length === 0 && (
          <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-1 tw-text-center">
            <img src={projectsImage} alt="Projects" className="tw-m-auto tw-h-32" />
            <h4 className="tw-m-0 tw-text-neutral-500">You don&apos;t have any projects yet</h4>
            <p className="tw-m-0 tw-text-neutral-500">Start a project to bring your design vision to life.</p>
            <Button color="neutralGray" onClick={() => window.location.assign('/create?tab=projects')}>
              Create a project
            </Button>
          </div>
        )}
        {displayedProjects?.map((project) => (
          <div
            key={project.id}
            className="tw-h-13 tw-flex tw-w-80 tw-items-center tw-justify-between tw-rounded-md tw-bg-white tw-py-4 tw-pl-4 tw-pr-2 tw-shadow-sm"
          >
            <div className="tw-flex tw-w-10/12 tw-items-center tw-gap-2 tw-overflow-hidden">
              <div className="tw-w-5">
                <FolderOpenIcon className="tw-h-5 tw-w-5 tw-text-neutral-800" />
              </div>
              <div className="tw-overflow-hidden">
                <LinkLikeButton
                  className="tw-m-0 tw-w-full tw-p-0 tw-text-start tw-text-sm tw-font-semibold"
                  onClick={() => handleProjectClick(project)}
                >
                  <div title={project.name} className="tw-truncate">
                    {project.name}
                  </div>
                </LinkLikeButton>
                <p className="tw-m-0 tw-w-full tw-truncate tw-text-xs tw-text-neutral-500">{project.description}</p>
              </div>
            </div>
            <div className="tw-flex tw-w-2/12 tw-items-center tw-gap-1">
              {project.ticketsRequiringAttentionCount > 0 && (
                <div className="tw-flex tw-aspect-square tw-min-h-6 tw-items-center tw-justify-center tw-rounded-full tw-bg-flushpink-500 tw-text-white">
                  {project.ticketsRequiringAttentionCount}
                </div>
              )}
              <IconFlyoutMenu color="transparent" icon={['far', 'ellipsis-v']}>
                {/* <IconFlyoutMenu.Button onClick={() => null}>
                  <PlusIcon className="tw-h-4 tw-w-4" />
                  <span className="tw-ml-2">Add requests</span>
                </IconFlyoutMenu.Button> */}
                {/* <IconFlyoutMenu.Button onClick={() => null}>
                  <CopyIcon className="tw-h-4 tw-w-4" />
                  <span className="tw-ml-2">Duplicate</span>
                </IconFlyoutMenu.Button> */}
                <DeleteProjectFlyoutButton project={project} />
              </IconFlyoutMenu>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
