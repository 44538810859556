import { ReactElement, ReactNode, useState } from 'react'

import { cn } from 'lib/util/cn'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { PopoverTrigger, Popover, PopoverContent } from '../popover/popover'

export interface DropdownClassNames {
  chevron: string
  chevronContainer: string
  chevronHoverColor: string
  popover: string
  popoverButton: string
  popoverItems: string
  popoverPanel: string
  titleContainer: string
  title: string
  label: string
}

interface DropdownIconProps {
  classNames?: DropdownClassNames
  disabled: boolean
  icon?: ReactElement | 'chevron'
  isUp: boolean
}

interface CustomSize {
  width?: string
  height?: string
  maxWidth?: string
  maxHeight?: string
  minWidth?: string
  minHeight?: string
}

const sizes = {
  xs: {
    height: '600px',
    width: '300px',
  },
  sm: {
    height: '600px',
    width: '412px',
  },
  md: {
    height: '600px',
    width: '760px',
  },
  lg: {
    height: '670px',
    width: '760px',
  },
  full: {
    height: '670px',
    width: '100%',
  },
  auto: {},
}

export type DropdownButton = (open: boolean) => JSX.Element

interface DropdownProps {
  buttonRingOnOpen?: boolean
  children?: ReactElement | string
  classNames?: DropdownClassNames
  customSize?: CustomSize
  disabled?: boolean
  icon?: ReactElement | 'chevron'
  label?: string | ReactElement
  openByDefault?: boolean
  render?: (close: () => void) => ReactElement
  size?: keyof typeof sizes
  title?: ReactNode
  button?: DropdownButton
  Tooltip?: ({ children }: { children: ReactNode }) => ReactElement
}

export const defaultClassNames = {
  chevron: `
    tw-w-5
    tw-h-4
  `,
  chevronContainer: `
    tw-relative
    tw--top-2
    tw-mr-1
    tw-ml-2
    tw-p-2
    tw-rounded-full
    tw-self-end
  `,
  chevronHoverColor: `
    tw-bg-neutral-100
  `,
  popover: `
    tw-relative
    tw-inline-block
    tw-text-left
  `,
  popoverButton: `
    tw-flex
    tw-flex-col
    tw-bg-white
    tw-text-gray-700
    tw-border-none
    tw-py-2
  `,
  popoverItems: `
    tw-absolute
    tw-origin-top-left
    tw-left-0
    tw-mt-2
    tw-w-30
    tw-rounded-md
    tw-shadow-lg
    tw-border 
    tw-border-solid 
    tw-border-neutral-200
    tw-bg-white
    focus:tw-outline-none
  `,
  popoverPanel: `
    tw-bg-white
    tw-p-8
    tw-mt-2
    tw-rounded-md
    tw-overflow-y-auto
  `,
  titleContainer: `
    tw-text-neutral-800
    tw-flex
    tw-align-center
    tw-flex-col
  `,
  title: `
    tw-font-semibold
  `,
  label: `
    tw-text-neutral-600
    tw-font-sm
    tw-pb-1
    tw-text-left
  `,
}

function DropdownIcon({ icon, isUp = true, disabled = false, classNames = defaultClassNames }: DropdownIconProps) {
  function Container({ children }: { children: ReactElement }): ReactElement {
    return <div className={cn(classNames.chevronContainer, { 'hover:tw-bg-neutral-100': !disabled })}>{children}</div>
  }

  if (icon === 'chevron') {
    if (isUp) {
      return (
        <Container>
          <ChevronUp className={classNames.chevron} aria-hidden="true" />
        </Container>
      )
    }

    return (
      <Container>
        <ChevronDown className={classNames.chevron} aria-hidden="true" />
      </Container>
    )
  }

  if (icon) {
    return <div>{icon}</div>
  }

  return null
}

export default function Dropdown({
  classNames = defaultClassNames,
  title,
  label,
  render,
  size = 'md',
  customSize,
  children,
  Tooltip,
  openByDefault = false,
  disabled = false,
  buttonRingOnOpen = false,
  icon,
  button,
}: DropdownProps): ReactElement {
  const [open, setOpen] = useState(openByDefault)

  const inlineSize = customSize ?? sizes[size]

  const disabledButtonStyles = { cursor: 'default', opacity: 0.6 }

  function ButtonWrapper({ children, ...otherProps }) {
    if (disabled) {
      return (
        <button {...otherProps} style={disabledButtonStyles} disabled={disabled}>
          {children}
        </button>
      )
    }

    return (
      <PopoverTrigger asChild data-testid="popover-trigger" {...otherProps}>
        {children}
      </PopoverTrigger>
    )
  }

  function popoverButton(open: boolean) {
    return (
      <ButtonWrapper
        className={cn(classNames.popoverButton, {
          'tw-rounded tw-ring tw-ring-cornflower-500': open && buttonRingOnOpen,
        })}
      >
        <div className="tw-flex !tw-flex-row tw-items-center">
          <div className={classNames.titleContainer}>
            {label && <div className={classNames.label}>{label}</div>}
            {title && <div className={classNames.title}>{title}</div>}
          </div>
          {icon && <DropdownIcon isUp={open} disabled={disabled} classNames={classNames} icon={icon} />}
        </div>
      </ButtonWrapper>
    )
  }

  const close = () => setOpen(false)

  return (
    <Popover open={open} onOpenChange={setOpen}>
      {button ? (
        <ButtonWrapper className={classNames.popoverButton}>{button(open)}</ButtonWrapper>
      ) : Tooltip ? (
        <Tooltip>{popoverButton(open)}</Tooltip>
      ) : (
        <>{popoverButton(open)}</>
      )}
      <PopoverContent
        align="start"
        intent="flyout"
        avoidCollisions={true}
        sideOffset={0}
        className={cn(classNames.popoverPanel, '!tw-max-h-[calc(100vh-200px)]')}
        collisionPadding={10}
        style={{ ...inlineSize, boxShadow: '0 10px 50px -12px' }}
        data-testid="popover-panel"
      >
        {render && render(close)}
        {children && children}
      </PopoverContent>
    </Popover>
  )
}
