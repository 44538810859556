import { createTicket, DesignRequest, DesignRequestStatus, updateTicket } from 'lib/api/tickets/tickets'
import { SkillCategorySkill } from 'lib/api/skills/skills'
import { toast } from 'lib/components/toast/toast'

export function isStatusCompleted(status: string): boolean {
  return status === DesignRequestStatus.completed
}

export function isStatusForReview(status: string): boolean {
  return status === DesignRequestStatus.review
}

export function isStatusOnDeck(status: string): boolean {
  return (
    status === DesignRequestStatus.onDeck ||
    status === DesignRequestStatus.preview ||
    status === DesignRequestStatus.wipOnDeck
  )
}

export function isStatusDraft(status: string): boolean {
  return status === DesignRequestStatus.draft
}

export function isStatusArchived(status: string): boolean {
  return status === DesignRequestStatus.archived
}

export function isStatusCompletedOrArchivedOrCanceled(status: string): boolean {
  return isStatusCompleted(status) || isStatusArchived(status) || isStatusCanceled(status)
}

export function isStatusActive(status: string): boolean {
  return !isStatusCompleted(status) && !isStatusDraft(status) && !isStatusArchived(status) && !isStatusCanceled(status)
}

export function haveFiles(ticketFilesCount: bigint | number): boolean {
  return ticketFilesCount > 0
}

export function isStatusCanceled(status: string): boolean {
  return status === DesignRequestStatus.canceled
}

export function isStatusInProgress(status: string): boolean {
  return status === DesignRequestStatus.inProgress
}

export function canBeRated(status: string): boolean {
  return ['archived', 'closed'].includes(status)
}

export function showDownloadAction(status: string, ticketFilesCount: bigint | number): boolean {
  return isStatusCompleted(status) && haveFiles(ticketFilesCount)
}

export function fileTypeSrc(format: string): string {
  try {
    return require(`/public/images/file_placeholders/${format}.png`)
  } catch (e) {
    console.error('Error getting file type src', e)
    return require('/public/images/file_placeholders/default.png')
  }
}

export async function createTicketAndRedirect(skill: SkillCategorySkill, preferredFormats: string[]): Promise<void> {
  const payload = {
    skill_id: skill.id,
  }

  const { error, ticket } = await createTicket(payload)

  if (error) {
    console.error('The ticket could not be created.', payload, error)
    toast.error('There was an error creating the ticket, try again')
    return
  }

  const preferredAvailableFormats = (ticket as DesignRequest).availableFormats.filter((format) =>
    preferredFormats.includes(format),
  )
  if (preferredAvailableFormats.length > 0) {
    try {
      await updateTicket(Number(ticket.id), { formats: preferredAvailableFormats })
    } catch (updateError) {
      toast.error('There was an error updating the ticket, try again')
      console.error('The ticket could not be updated.', updateError)
    }
  }

  window.location.assign(`/tickets/${ticket.id}`)
}
