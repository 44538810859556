import { ColumnDef, flexRender, getCoreRowModel, RowSelectionState, useReactTable } from '@tanstack/react-table'
import Button from 'components/core/button'
import { Ticket } from 'interfaces/ticket'
import { TicketStatusBadge } from 'lib/components/badge/badge'
import Checkbox from 'lib/components/checkbox/checkbox'
import { PlusIcon, TicketIcon } from 'lucide-react'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { request } from 'lib/api/fetch-api'
import { Skeleton } from 'lib/components/skeleton/skeleton'
import { useInView } from 'react-intersection-observer'
import { toast } from 'sonner'
import { Brand } from 'interfaces/brand'
import { Project } from 'interfaces/project'
import { BrandColor } from 'components/pages/request/brands/brands'
import DeliverablesTableTitleCell from './deliverables-table-title-cell'
import { cn } from 'lib/util/cn'

const CellWrapper = ({ isSelected, children }: { isSelected: boolean; children: ReactNode }) => (
  <span className={cn({ 'tw-text-neutral-800': isSelected })}>{children}</span>
)

const ProjectCell = ({
  projectId,
  project,
  isSelected,
}: {
  projectId: number
  project: Project
  isSelected: boolean
}) => {
  if (!project) return <CellWrapper isSelected={isSelected}>-</CellWrapper>
  if (project.id === projectId) return <CellWrapper isSelected={isSelected}>Already in this project</CellWrapper>
  return <CellWrapper isSelected={isSelected}>{project.name}</CellWrapper>
}

const BrandCell = ({ brand, isSelected }: { brand: Brand | null; isSelected: boolean }) => {
  if (!brand) return <CellWrapper isSelected={isSelected}>-</CellWrapper>
  return (
    <CellWrapper isSelected={isSelected}>
      <span>{brand.name}</span>
      <span className="tw-flex tw-flex-row tw-gap-1">
        {brand?.colors?.map((color) => <BrandColor variant={{ size: 'sm' }} key={color.id} color={color.value} />)}
      </span>
    </CellWrapper>
  )
}

interface AddDeliverablesTableProps {
  projectId: number
  onFinished: (update?: boolean) => void
  handleCreateDeliverablesClick: () => void
  isAdmin?: boolean
}

const AddDeliverablesTable = ({
  projectId,
  onFinished,
  handleCreateDeliverablesClick,
  isAdmin,
}: AddDeliverablesTableProps) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const { ref, inView } = useInView()
  const queryClient = useQueryClient()

  const columns: ColumnDef<Ticket>[] = [
    {
      id: 'select-col',
      cell: ({ row, table }) => (
        <Checkbox
          className="tw-m-4"
          isChecked={row.getIsSelected() || row.original.project?.id === projectId}
          disabled={!row.getCanSelect()}
          onClick={(e) => {
            row.getToggleSelectedHandler()(e)
            table.setGlobalFilter(row.original.subject)
          }}
        />
      ),
      size: 8,
    },
    {
      id: 'title',
      header: 'Title',
      cell: (props) => <DeliverablesTableTitleCell ticket={props.row.original} isAdmin={isAdmin} />,
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'friendlyStatusName',
      cell: (props) => <TicketStatusBadge status={props.cell.getValue() as string} />,
    },
    {
      id: 'project',
      header: 'Project',
      accessorKey: 'project',
      cell: (props) => (
        <ProjectCell
          projectId={projectId}
          project={props.cell.getValue() as Project}
          isSelected={props.row.getIsSelected()}
        />
      ),
    },
    {
      id: 'brand',
      header: 'Brand',
      accessorKey: 'brand',
      cell: (props) => <BrandCell brand={props.cell.getValue() as Brand} isSelected={props.row.getIsSelected()} />,
    },
  ]

  const addTicketsToProjectMutation = useMutation({
    mutationFn: (ticketIds: number[]) =>
      request({
        endpoint: 'addTicketsToProject',
        query: { id: projectId },
        body: { ticket_ids: ticketIds },
      }),
    onSuccess: () => {
      toast.success('Requests added to project')
      queryClient.invalidateQueries({ queryKey: ['projectDeliverables', projectId] })
      onFinished()
    },
    onError: (e) => {
      toast.error('Error adding requests to project')
      console.error('Error adding requests to project', e)
    },
  })

  const { data, isLoading, error, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ['allTickets'],
    queryFn: ({ pageParam = 1 }) => request({ endpoint: 'getAllTickets', query: { page: pageParam } }),
    getNextPageParam: (lastPage, pages) => (lastPage.meta?.nextPage ? pages.length + 1 : undefined),
    retry: 3,
  })

  const tableData = useMemo(() => data?.pages.flatMap((page) => page.data), [data?.pages]) ?? []

  const table = useReactTable({
    columns,
    data: tableData,
    state: {
      rowSelection,
    },
    enableRowSelection: (row) => row.original.project?.id !== projectId,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
  })

  const handleAddDeliverablesClick = () => {
    const ticketIds = Object.keys(rowSelection).map((key) => tableData[key].id)
    addTicketsToProjectMutation.mutate(ticketIds)
  }

  useEffect(() => {
    if (error) {
      toast.error('Failed to fetch requests')
      console.error('Failed to fetch requests', error)
    }
  }, [error])

  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView, isFetchingNextPage])

  return (
    <div>
      <div className="tw-mb-4 tw-flex tw-items-center tw-justify-between">
        <h5>
          <TicketIcon className="lu-md" /> Requests ({data?.pages[0].meta.total ?? 0})
        </h5>
        <Button
          onClick={handleCreateDeliverablesClick}
          className="tw-flex tw-items-center tw-justify-center tw-gap-2"
          color="neutral"
        >
          <PlusIcon className="lu-md" /> <span>Create request</span>
        </Button>
      </div>
      <div>
        <table className="tw-w-full">
          <thead className="tw-sticky tw--top-4 tw-z-10">
            <tr className="tw-h-12 tw-bg-peppercorn-50">
              {table.getFlatHeaders().map((header) => (
                <th
                  key={header.id}
                  style={{ width: `${header.getSize()}px` }}
                  className="tw-font-medium tw-uppercase tw-text-black"
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={cn('tw-h-24 tw-border-s-2 tw-border-solid tw-border-peppercorn-100 tw-p-2', {
                  'tw-bg-cornflower-50': row.getIsSelected(),
                })}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
            <tr ref={ref} className="tw-h-4" />
          </tbody>
        </table>
        {(isLoading || isFetchingNextPage) && (
          <Skeleton className="tw-h-24 tw-w-full tw-bg-neutral-200" data-testid="skeleton" />
        )}
      </div>
      <div className="tw-sticky tw-bottom-0 tw-mt-4 tw-flex tw-w-full tw-justify-end tw-gap-2 tw-bg-white tw-py-4">
        <Button onClick={() => onFinished(false)} color="lightGray">
          Cancel
        </Button>
        <Button onClick={handleAddDeliverablesClick} color="purple" disabled={Object.keys(rowSelection).length === 0}>
          Add requests to project
        </Button>
      </div>
    </div>
  )
}

export default AddDeliverablesTable
