import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import GrowingTextInput from 'components/core/text-input/growing-text-input'
import { Brand } from 'interfaces/brand'
import { request, requestQuery } from 'lib/api/fetch-api'
import CopyTextButton from 'lib/components/buttons/copy-text-button'
import IconButton from 'lib/components/buttons/icon-button'
import { Drawer } from 'lib/components/drawer/drawer'
import { displayDate } from 'lib/util/date'
import {
  ArrowLeftIcon,
  ArrowRightToLineIcon,
  CheckIcon,
  FolderOpenIcon,
  FoldersIcon,
  LinkIcon,
  NotepadTextIcon,
  PencilIcon,
  XIcon,
} from 'lucide-react'
import { useUserContext } from 'providers/user-provider'
import { ChangeEvent, useEffect, useState } from 'react'
import BrandsPopover from '../brands-popover'
import { toast } from 'lib/components/toast/toast'
import { LoadingScreen } from 'components/pages/requests/empty-screens'
import CompanyTaggingProvider, { ChangeTagAction } from 'providers/company-tagging-provider'
import CompanyTaggingPopover from '../company-tagging/company-tagging-popover'
import { CompanyTag } from 'lib/api/company-tags/company-tags'
import { useAllCompanyTagsContext } from 'providers/all-company-tags-provider'
import ProjectDeliverablesTable from './project-deliverables-table'
import { Project } from 'interfaces/project'
import AddDeliverablesTable from './add-deliverables-table'
import { Ticket } from 'interfaces/ticket'
import Button, { LinkLikeButton } from 'components/core/button'
import Textarea from 'lib/components/textarea/textarea'
import CreateDeliverablesTable from './create-deliverables-table'
import Tooltip from 'lib/components/tooltip/tooltip'
type DrawerState = 'details' | 'add-deliverables' | 'remove-deliverables' | 'read-only' | 'create-deliverables'
export interface ProjectDrawerProps {
  isOpen: boolean
  setOpen: (open: boolean) => void
  projectId?: number
  onUpdate?: () => void
  project?: Project
  readOnly?: boolean
}

export default function ProjectDrawer({
  isOpen,
  setOpen,
  projectId,
  onUpdate,
  project: passedProject,
  readOnly,
}: ProjectDrawerProps) {
  const [drawerState, setDrawerState] = useState<DrawerState>(readOnly ? 'read-only' : 'details')
  const [deliverablesToRemove, setDeliverablesToRemove] = useState<Ticket[]>([])

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['project', projectId],
    queryFn: requestQuery({ endpoint: 'getProject', query: { id: projectId } }),
    enabled: isOpen && !readOnly,
  })

  const fetchedProject = data?.data
  const project = readOnly ? passedProject || fetchedProject : fetchedProject

  const handleRemoveDeliverablesClick = (deliverablesToRemove: Ticket[]) => {
    setDeliverablesToRemove(deliverablesToRemove)
    setDrawerState('remove-deliverables')
  }

  const onUpdateProject = () => {
    refetch()
    onUpdate?.()
  }

  return (
    <Drawer isOpen={isOpen} setOpen={setOpen} size="md">
      {drawerState === 'read-only' && (
        <ProjectReadOnlyContent project={project} setOpen={setOpen} isLoading={isLoading} />
      )}
      {drawerState === 'details' && (
        <ProjectDetailsContent
          isOpen={isOpen}
          setOpen={setOpen}
          project={project}
          isLoading={isLoading}
          setDrawerState={setDrawerState}
          onRemoveDeliverablesClick={handleRemoveDeliverablesClick}
          onUpdate={onUpdateProject}
        />
      )}
      {drawerState === 'add-deliverables' && (
        <AddDeliverablesContent
          project={project}
          setDrawerState={setDrawerState}
          setOpen={setOpen}
          onUpdate={onUpdateProject}
        />
      )}
      {drawerState === 'remove-deliverables' && (
        <RemoveDeliverablesContent
          project={project}
          setDrawerState={setDrawerState}
          setOpen={setOpen}
          deliverablesToRemove={deliverablesToRemove}
          setDeliverablesToRemove={setDeliverablesToRemove}
        />
      )}
      {drawerState === 'create-deliverables' && (
        <CreateDeliverablesContent
          project={project}
          setDrawerState={setDrawerState}
          setOpen={setOpen}
          onUpdate={onUpdateProject}
        />
      )}
    </Drawer>
  )
}

interface ProjectReadOnlyContentProps {
  project: Project
  setOpen: (open: boolean) => void
  isLoading: boolean
}

function ProjectReadOnlyContent({ project, setOpen, isLoading }: ProjectReadOnlyContentProps) {
  return (
    <>
      <Drawer.Header setOpen={setOpen} showCloseButton={false} className="tw-static">
        <div className="tw-flex tw-w-full tw-items-center tw-gap-4">
          <IconButton size="xs" color="transparent" onClick={() => setOpen(false)} className="tw-mr-2">
            <ArrowRightToLineIcon className="tw-text-neutral-700" />
          </IconButton>
          <FoldersIcon className="lu-xl tw-text-neutral-700" />
          {!isLoading ? (
            <div className="tw-flex tw-flex-col tw-justify-center tw-gap-2 tw-overflow-hidden">
              <Tooltip content={project?.name}>
                <h3 className="tw-m-0 tw-truncate tw-p-0">{project?.name}</h3>
              </Tooltip>
              <div>Last updated on {displayDate(project?.updatedAt)}</div>
            </div>
          ) : (
            <div className="tw-flex tw-flex-col tw-justify-center tw-gap-2">
              <div>Loading...</div>
            </div>
          )}
        </div>
      </Drawer.Header>
      <Drawer.Body className="tw-overflow-y-auto">
        {!isLoading ? (
          <>
            <div className="tw-mb-4">
              <h5 className="tw-flex tw-items-center tw-gap-1">
                <NotepadTextIcon className="lu-md" />
                <span>Description</span>
              </h5>
              <ExpandableDescription text={project?.description} />
            </div>
            <ProjectDeliverablesTable projectId={project?.id} readOnly />
          </>
        ) : (
          <LoadingScreen />
        )}
      </Drawer.Body>
    </>
  )
}

interface ProjectDetailsContentProps {
  isOpen: boolean
  setOpen: (open: boolean) => void
  project: Project
  isLoading: boolean
  setDrawerState: (state: DrawerState) => void
  onRemoveDeliverablesClick: (deliverablesToRemove: Ticket[]) => void
  onUpdate?: () => void
}

function ProjectDetailsContent({
  isOpen,
  setOpen,
  project,
  isLoading,
  setDrawerState,
  onRemoveDeliverablesClick,
  onUpdate,
}: ProjectDetailsContentProps) {
  const [projectName, setProjectName] = useState<string>()
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedBrand, setSelectedBrand] = useState<Brand>()
  const { companyTags } = useAllCompanyTagsContext()
  const { user } = useUserContext()
  const [editDescription, setEditDescription] = useState(false)
  const [description, setDescription] = useState(project?.description)
  const [showProjectNameError, setShowProjectNameError] = useState(false)
  const queryClient = useQueryClient()

  const updateProjectMutation = useMutation({
    mutationFn: ({ brand, description, name }: { brand?: Brand; description?: string; name?: string }) => {
      return request({
        endpoint: 'updateProject',
        query: { id: project.id },
        body: {
          brand_id: brand?.id || null,
          description,
          name,
        },
      })
    },
    onSuccess: async (data: { data: Project }) => {
      toast.success('Project updated')

      queryClient.setQueryData(['project', project.id], (old: Project) => ({ ...old, ...data.data }))
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['projects'] })
      queryClient.invalidateQueries({ queryKey: ['project', project.id] })
    },
    onError: (error) => {
      toast.error('Failed to update project')
      console.error('Failed to update project', error)
    },
  })

  function handleUpdateTaggableState(tag: CompanyTag, action: ChangeTagAction) {
    switch (action) {
      case ChangeTagAction.Select:
        setSelectedTags([...selectedTags, tag])
        break
      case ChangeTagAction.Unselect:
      case ChangeTagAction.Delete:
        setSelectedTags(selectedTags.filter((selectedTag) => selectedTag.id !== tag.id))
        break
      case ChangeTagAction.Update:
        setSelectedTags(selectedTags.map((selectedTag) => (selectedTag.id === tag.id ? tag : selectedTag)))
        break
      default:
        break
    }
  }

  function handleDescriptionChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setDescription(event.target.value)
  }

  function resetDescription() {
    setDescription(project?.description)
    setEditDescription(false)
  }

  function saveDescription() {
    updateProjectMutation.mutate({ description: description.trim() })
    setEditDescription(false)
  }

  function saveProjectName() {
    if (projectName.trim() === project?.name) {
      return
    }

    if (projectName.trim() === '') {
      toast.error('Project name required')
      setShowProjectNameError(true)
      return
    }

    updateProjectMutation.mutate({ name: projectName.trim() })
  }

  useEffect(() => {
    function detailsHaveChanged() {
      return projectName !== project?.name || description !== project?.description || selectedTags !== project?.tags
    }

    if (!isOpen && detailsHaveChanged()) {
      onUpdate?.()
    }
  }, [description, isOpen, onUpdate, project?.description, project?.name, project?.tags, projectName, selectedTags])

  useEffect(() => {
    if (project) {
      setProjectName(project?.name)
      setSelectedBrand(project?.brand)
      setDescription(project?.description)
      setSelectedTags(project?.tags || [])
    }
  }, [project])

  useEffect(() => {
    setSelectedTags((prevSelectedTags) => {
      return prevSelectedTags
        .filter((selectedTag) => companyTags.some((companyTag) => companyTag.id === selectedTag.id))
        .map((selectedTag) => companyTags.find((tag) => tag.id === selectedTag.id))
    })
  }, [companyTags])

  return (
    <>
      <Drawer.Header setOpen={setOpen} showCloseButton={false} className="tw-static">
        <div className="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-pb-2 tw-pt-4">
          <div className="tw-flex tw-w-full tw-items-center tw-gap-4">
            <IconButton size="xs" color="transparent" onClick={() => setOpen(false)} className="tw-mr-2">
              <ArrowRightToLineIcon className="tw-text-neutral-700" />
            </IconButton>
            <FolderOpenIcon className="lu-xl tw-text-neutral-700" />
            {!isLoading ? (
              <div className="tw-flex tw-flex-col tw-justify-center tw-gap-2" data-testid="project-name-input">
                <div className="tw-flex tw-items-center tw-gap-2">
                  <GrowingTextInput
                    value={projectName || ''}
                    onChange={(e) => setProjectName(e.target.value)}
                    onBlur={saveProjectName}
                    name="project-name"
                    className="tw-w-full"
                    extraWidth={50}
                    hasError={showProjectNameError}
                  />
                  {showProjectNameError && <div className="tw-text-red-500">Project name required</div>}
                </div>
                <div>Last updated on {displayDate(project?.updatedAt)}</div>
              </div>
            ) : (
              <div className="tw-flex tw-flex-col tw-justify-center tw-gap-2">
                <div>Loading...</div>
              </div>
            )}
          </div>
          <div>
            <CopyTextButton textToCopy={window.location.href} defaultIcon={<LinkIcon />} />
          </div>
        </div>
      </Drawer.Header>
      <Drawer.Body className="tw-overflow-y-auto">
        {!isLoading ? (
          <>
            <div className="tw-flex tw-items-center">
              <BrandsPopover
                onChange={(brand) => {
                  setSelectedBrand(brand)
                  updateProjectMutation.mutate({ brand })
                }}
                brand={selectedBrand}
                companyId={user?.companyId}
              />
              <div className="tw-ml-4 tw-mr-10 tw-h-5 tw-w-[1px] tw-bg-neutral-600" />
              <CompanyTaggingProvider
                selectedTags={selectedTags}
                updateTaggableState={handleUpdateTaggableState}
                taggableId={project?.id}
                taggableType="Project"
                warningMessage="Adding and removing project tags does not add nor remove tags from the associated requests"
              >
                <CompanyTaggingPopover />
              </CompanyTaggingProvider>
            </div>
            <div>
              <h5>
                <NotepadTextIcon className="lu-md" /> Description
              </h5>
              {editDescription ? (
                <div className="tw-flex tw-items-center tw-gap-2">
                  <Textarea
                    className="tw-flex-1"
                    placeholder="Enter a description for the project"
                    value={description}
                    onChange={handleDescriptionChange}
                  />
                  <div className="tw-flex tw-gap-2">
                    <IconButton color="secondary" onClick={saveDescription}>
                      <CheckIcon className="lu-sm" />
                    </IconButton>
                    <IconButton color="secondary" onClick={resetDescription}>
                      <XIcon className="lu-sm" />
                    </IconButton>
                  </div>
                </div>
              ) : (
                <div className="tw-flex tw-items-center tw-gap-2">
                  <p className="tw-flex-1 tw-overflow-hidden tw-text-ellipsis tw-break-words">{description}</p>
                  <IconButton
                    className="tw-mb-4"
                    color="secondary"
                    onClick={() => setEditDescription(true)}
                    dataTestid="edit-description"
                  >
                    <PencilIcon className="lu-sm" />
                  </IconButton>
                </div>
              )}
            </div>
            <ProjectDeliverablesTable
              projectId={project?.id}
              handleAddDeliverablesClick={() => setDrawerState('add-deliverables')}
              handleRemoveDeliverablesClick={onRemoveDeliverablesClick}
            />
          </>
        ) : (
          <LoadingScreen />
        )}
      </Drawer.Body>
    </>
  )
}

interface DeliverablesContentProps {
  project: Project
  setDrawerState: (state: DrawerState) => void
  setOpen: (open: boolean) => void
  onUpdate: () => void
}

function AddDeliverablesContent({ project, setDrawerState, setOpen, onUpdate }: DeliverablesContentProps) {
  const onFinished = (update = true) => {
    if (update) {
      onUpdate()
    }
    setDrawerState('details')
  }

  return (
    <>
      <Drawer.Header setOpen={setOpen} showCloseButton={false} className="tw-static">
        <div className="tw-flex tw-items-center tw-gap-4">
          <IconButton
            size="xs"
            color="transparent"
            onClick={() => setDrawerState('details')}
            className="tw-mr-2"
            data-testid="BackButton"
          >
            <ArrowLeftIcon className="tw-text-neutral-700" />
          </IconButton>
          <h3 className="tw-m-0 tw-p-0">Add Requests to &quot;{project.name}&quot;</h3>
        </div>
      </Drawer.Header>
      <Drawer.Body className="tw-pb-0 tw-pt-4">
        <div className="tw-mt-4">
          <AddDeliverablesTable
            projectId={project.id}
            onFinished={onFinished}
            handleCreateDeliverablesClick={() => setDrawerState('create-deliverables')}
          />
        </div>
      </Drawer.Body>
    </>
  )
}

interface RemoveDeliverablesContentProps {
  project: Project
  setDrawerState: (state: DrawerState) => void
  setOpen: (open: boolean) => void
  deliverablesToRemove: Ticket[]
  setDeliverablesToRemove: (deliverables: Ticket[]) => void
}

function RemoveDeliverablesContent({
  project,
  setDrawerState,
  setOpen,
  deliverablesToRemove,
  setDeliverablesToRemove,
}: RemoveDeliverablesContentProps) {
  const queryClient = useQueryClient()

  const handleCancel = () => {
    setDeliverablesToRemove([])
    setDrawerState('details')
  }

  const handleRemoveMutation = useMutation({
    mutationFn: () => {
      return request({
        endpoint: 'removeTicketsFromProject',
        query: { id: project.id },
        body: { ticket_ids: deliverablesToRemove.map((d) => Number(d.id)) },
      })
    },
    onSuccess: () => {
      toast.success('Requests removed')
      queryClient.invalidateQueries({ queryKey: ['projectDeliverables', project.id] })
      setDeliverablesToRemove([])
      setDrawerState('details')
    },
    onError: (error) => {
      toast.error('Failed to remove requests')
      console.error('Failed to remove requests', error)
    },
  })

  const handleRemove = () => {
    handleRemoveMutation.mutate()
  }

  const firstDeliverableName = `"${deliverablesToRemove[0]?.subject || `Untitled ${deliverablesToRemove[0]?.skillName}`}"`

  const deliverableText = () => {
    if (deliverablesToRemove.length > 1) {
      return (
        <span className="tw-font-semibold">
          {firstDeliverableName}
          <span className="tw-font-normal"> + {deliverablesToRemove.length - 1} more requests</span>
        </span>
      )
    }
    return <span className="tw-font-semibold">{firstDeliverableName}</span>
  }

  return (
    <>
      <Drawer.Header setOpen={setOpen} showCloseButton={false} className="tw-static">
        <div className="tw-flex tw-items-center tw-gap-4">
          <IconButton
            size="xs"
            color="transparent"
            onClick={() => setDrawerState('details')}
            className="tw-mr-2"
            data-testid="BackButton"
          >
            <ArrowLeftIcon className="tw-text-neutral-700" />
          </IconButton>
          <h3 className="tw-m-0 tw-p-0">Remove Request(s) from Project?</h3>
        </div>
      </Drawer.Header>
      <Drawer.Body className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-overflow-y-auto">
        <h5 className="tw-text-normal tw-m-0 tw-break-all tw-p-0 tw-py-4 tw-font-normal">
          Are you sure you want to remove {deliverableText()} from the{' '}
          <span className="tw-font-semibold">&quot;{project.name}&quot;</span>?
        </h5>
        <p className="tw-text-md tw-m-0 tw-p-0">
          This will leave the requests as standalone requests without any project association.
        </p>
        <div className="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center">
          <img src="/images/pickle_remove_deliverables_warning.png" alt="Remove ticket" className="tw-w-1/2" />
        </div>
        <div className="tw-flex tw-w-full tw-justify-end tw-gap-2 tw-bg-white tw-py-4">
          <Button onClick={handleCancel} color="lightGray">
            Cancel
          </Button>
          <Button onClick={handleRemove} color="red">
            Remove requests
          </Button>
        </div>
      </Drawer.Body>
    </>
  )
}

function CreateDeliverablesContent({ project, setDrawerState, setOpen, onUpdate }: DeliverablesContentProps) {
  const onFinished = () => {
    onUpdate()
    setDrawerState('details')
  }

  return (
    <>
      <Drawer.Header setOpen={setOpen} showCloseButton={false} className="tw-static">
        <div className="tw-flex tw-items-center tw-gap-4">
          <IconButton
            size="xs"
            color="transparent"
            onClick={() => setDrawerState('details')}
            className="tw-mr-2"
            data-testid="BackButton"
          >
            <ArrowLeftIcon className="tw-text-neutral-700" />
          </IconButton>
          <h3 className="tw-m-0 tw-p-0">Create Requests for &quot;{project.name}&quot;</h3>
        </div>
      </Drawer.Header>
      <Drawer.Body className="tw-pb-0 tw-pt-4">
        <div className="tw-mt-4">
          <CreateDeliverablesTable
            projectId={project.id}
            onFinished={onFinished}
            onCancel={() => setDrawerState('add-deliverables')}
          />
        </div>
      </Drawer.Body>
    </>
  )
}

function ExpandableDescription({ text }: { text: string }) {
  const [isExpanded, setIsExpanded] = useState(false)

  if (!text) {
    return (
      <div>
        <p className="tw-m-0 tw-p-0">No description</p>
      </div>
    )
  }

  return (
    <div>
      <p className="tw-m-0 tw-p-0">{text.length > 200 && !isExpanded ? `${text.substring(0, 200)}...` : text}</p>
      {text.length > 200 && (
        <span className="tw-flex tw-items-center tw-justify-end">
          <LinkLikeButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? 'Show less' : 'Show more'}
          </LinkLikeButton>
        </span>
      )}
    </div>
  )
}
